<template>
  <div class="side">
    <Navigation :bgColor='bgColor' :color='color' />
    <img src="~img/54.png" alt="">
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>



<script>
// import panav from "@/components/pc/nav.vue";
import Navigation from "@/components/ipad/Navigation.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    Navigation,
    horserace,
  },
  data() {
    return {
      color: "#0a9161",
      bgColor: "#add4f7",
      background: "#0a9161",
      noticeColor: "#fff",
    };
  },
};
</script>


<style lang="less" scoped>
.side {
  height: 100vh;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .notice {
    height: 50px;
    line-height: 50px;
    font-size: 24px;
  }
}
</style>
